body {
  font-family: 'Roboto', 'Helvetica Neue', Arial, sans-serif;
  padding: 0;
  margin: 0;
}
h1 {
  font-family: 'Lora', serif;
}
p {
  font-family: 'Roboto', 'Helvetica Neue', Arial, sans-serif;
}
.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
    background: url(./img/sideaudi.jpg) no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: auto;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #ccc;
}
.Footer {
background-color: #282c34;
font-size: .55em;
line-height: .75;
color: #e3e6ea;
opacity: 0.9;
min-width: 100%;
bottom: 0;
position: absolute;
}
.Footer a {
  text-decoration: none;
  display: flex;
  justify-content: right;
  font-size: .75em;
  color: #e3e6ea;
  bottom: 0;
}
.Footer a:visited {
  text-decoration: none;
  display: flex;
  justify-content: right;
  font-size: .75em;
  color: #e3e6ea;
  bottom: 0;
}
