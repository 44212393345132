body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  font-family: 'Roboto', 'Helvetica Neue', Arial, sans-serif;
  padding: 0;
  margin: 0;
}
h1 {
  font-family: 'Lora', serif;
}
p {
  font-family: 'Roboto', 'Helvetica Neue', Arial, sans-serif;
}
.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
    background: url(/static/media/sideaudi.e6dd3638.jpg) no-repeat center center fixed;
    background-size: cover;
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: auto;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #ccc;
}
.Footer {
background-color: #282c34;
font-size: .55em;
line-height: .75;
color: #e3e6ea;
opacity: 0.9;
min-width: 100%;
bottom: 0;
position: absolute;
}
.Footer a {
  text-decoration: none;
  display: flex;
  justify-content: right;
  font-size: .75em;
  color: #e3e6ea;
  bottom: 0;
}
.Footer a:visited {
  text-decoration: none;
  display: flex;
  justify-content: right;
  font-size: .75em;
  color: #e3e6ea;
  bottom: 0;
}

